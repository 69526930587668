import React from 'react'
import { Link, useParams, useNavigate } from 'react-router-dom'
import $ from 'jquery'

export default function Book(props) 
{
    const navigate = useNavigate();
    const params = useParams();
    const BID = params.BID;
    let limit = 0;
    // const bName = params.bName;
    let CurrentBook = [];
    let CompanyDetails = [];
    if(props.getCourse)
    {
        // CurrentBook = props.getCourse.filter((cBook) => {
        //     return cBook.id == BID;
        // });

        props.getCourse.map((value, key) => (
            value.id == BID
            ?(
                CurrentBook['cName'] = value.cName,
                CurrentBook['id'] = value.id,
                CurrentBook['cPrice'] = value.cPrice,
                CurrentBook['cDiscount'] = value.cDiscount,
                CurrentBook['cShortName'] = value.cShortName,
                CurrentBook['cDetail'] = value.cDetail,
                CurrentBook['cCatID'] = value.CatID,
                CurrentBook['status'] = value.status
            )
            : null
        ));

    }
    if(props.getCompanyDetails)
    {
        props.getCompanyDetails.map((value, key) => (
            CompanyDetails['CompanyName'] = value.companyName,
            CompanyDetails['CompanyAddress'] = value.companyAddress,
            CompanyDetails['CompanyEmail'] = value.companyEmail,
            CompanyDetails['CompanyAddressLink'] = value.companyAddressLink,
            CompanyDetails['CompanyPhone'] = value.companyPhone,
            CompanyDetails['CompanyFacebook'] = value.companyFacebook,
            CompanyDetails['CompanyTwitter'] = value.companyTwitter,
            CompanyDetails['CompanyInstagram'] = value.companyInstagram,
            CompanyDetails['CompanyLinkedin'] = value.companyLinkedin,
            CompanyDetails['CompanyCurrency'] = value.companyCurrency
        ));
    }

    setInterval(function()
    {
        let Scr = $(".Slider").scrollLeft() + 204;
        if(Scr <= ($('.Slider').prop("scrollWidth") - $('.Slider').width()))
        {
            $(".Slider").scrollLeft(Scr);
        }
        else if((Scr - 204) < ($('.Slider').prop("scrollWidth") - $('.Slider').width())){
          $(".Slider").scrollLeft(($('.Slider').prop("scrollWidth") - $('.Slider').width()));
        }
        else
        {
            $(".Slider").scrollLeft(0);
        }
    }, 5000);

    return (
        <>
            <div className='crumb'>
                <Link to="/Home">Home</Link>&nbsp;&nbsp; / &nbsp;&nbsp;<strong>{CurrentBook['cName']} Book Detail</strong>
            </div>
            <div className='container'>
                <div className='row mt-3'>
                    <div className='col-lg-8'>
                        <div className='text-center m-3'><h4 className='NormalHeading'>{CurrentBook['cName']}</h4></div>
                        <img src={`./assets/images/${CurrentBook['id']}.webp`} alt={` ${CurrentBook['cName']} - ${CompanyDetails['CompanyName']} `} title={` ${CurrentBook['cName']} - ${CompanyDetails['CompanyName']} `} className='img-fluid'  />
                        <div className='DetailDiv'>
                            <span className='DetailHeading'>Book Detail</span>
                            <p style={{"fontSize":"20px"}} className='p-2 DetailText' align="justify">{CurrentBook['cDetail']}</p>
                            <ul className='contactList'>
                                <li><span className='mylabel'>Price(Dis. INC):</span> &nbsp;&nbsp;{ (CurrentBook['cPrice'] - ((CurrentBook['cDiscount'] / CurrentBook['cPrice']) * 100)).toFixed(2) }{CompanyDetails['CompanyCurrency']}</li>
                                <li><span className='mylabel'>Dicsount:</span> {CurrentBook['cDiscount']}{CompanyDetails['CompanyCurrency']} </li>
                                <li><Link to="/"><i className='fa fa-map-marker myIcon'></i> {CompanyDetails['CompanyAddress']} </Link> </li>
                                <li><Link to={`mailto:${CompanyDetails['CompanyEmail']}`}><i className='fa fa-at myIcon'></i> {CompanyDetails['CompanyEmail']} </Link></li>
                                <li><Link to={`tel:${CompanyDetails['CompanyPhone']}`}><i className='fa fa-phone myIcon'></i> {CompanyDetails['CompanyPhone']} </Link></li>
                                <li><Link to={CompanyDetails['CompanyFacebook']}><i className='fa fa-facebook myIcon'></i> {CompanyDetails['CompanyFacebook']} </Link></li>
                                <li><Link to={CompanyDetails['CompanyTwitter']}><i className='fa fa-twitter myIcon'></i> {CompanyDetails['CompanyTwitter']} </Link></li>
                                <li><Link to={CompanyDetails['CompanyInstagram']}><i className='fa fa-instagram myIcon'></i> {CompanyDetails['CompanyInstagram']} </Link></li>
                                <li><Link to={CompanyDetails['CompanyLinkedin']}><i className='fa fa-linkedin myIcon'></i> {CompanyDetails['CompanyLinkedin']} </Link></li>
                                <li className='text-end'>
                                    <Link to={`/PayNow/${CurrentBook['id']}`} className="btn btn-outline-primary text-white round-0 myBtn CutStyle">
                                        Pay Now
                                        <span className="borderCut TopLeftCut"></span>
                                        <span className="borderCut BottomRightCut"></span>
                                        <span className="borderCut BottomLeftCut"></span>
                                        <span className="borderCut TopRightCut"></span>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className='col-lg-4'>
                        <div className='text-center m-3'><h4 className='NormalHeading'>Related Books</h4></div>
                        { props.getCourse && props.getCourse.map((value, key) => 
                        (
                        value.status == 1 && limit < 3 && value.id != BID && value.CatID == CurrentBook['cCatID']
                        ?(
                            limit++,
                            <div className='course mt-4' key={key}>
                                <div className='imgView' onClick={() => navigate(`/Book/${value.id}/${value.cName}`)}>
                                <img src={`./assets/images/${value.id}.webp` } alt={` ${value.cName} - ${CompanyDetails['CompanyName']} `} title={` ${value.cName} - ${CompanyDetails['CompanyName']} `} className='img-fluid' />
                                </div>
                                <div className='m-2'>
                                <h4>{value.cName}</h4>
                                <p align="justify">{value.cDetail.slice(0, 150)}</p>
                                <span className='mylabel bookOff'>Off {value.cDiscount}%</span>
                                <span className='mylabel bookPrice'>{ (value.cPrice - ((value.cDiscount / value.cPrice) * 100)).toFixed(2) }{CompanyDetails['CompanyCurrency']}</span>
                                </div>
                                <div className='ms-auto' style={{"width":"fit-content"}}>
                                <Link to={`/Book/${value.id}/${value.cName}`} className="btn btn-outline-primary round-0 myBtn CutStyle">
                                    Download
                                    <span className="borderCut TopLeftCut"></span>
                                    <span className="borderCut BottomRightCut"></span>
                                    <span className="borderCut BottomLeftCut"></span>
                                    <span className="borderCut TopRightCut"></span>
                                </Link>
                                <Link to={`/Book/${value.id}/${value.cName}`} className="btn btn-outline-primary round-0 myBtn CutStyle">
                                    More
                                    <span className="borderCut TopLeftCut"></span>
                                    <span className="borderCut BottomRightCut"></span>
                                    <span className="borderCut BottomLeftCut"></span>
                                    <span className="borderCut TopRightCut"></span>
                                </Link>
                                </div>
                            </div>
                        )
                        :null
                    ))}
                    </div>
                </div>
                <div className='col-lg-12 m-3 text-center mt-5'>
                    <h2 className='MainHeading'>Accepted Payment Method</h2>
                    <div className='Slider hScroll mt-5'>
                    { props.getPaymentMethods && props.getPaymentMethods.map((value, key) => 
                        (
                        <div key={key} className='Slide'>
                        <img src={`./assets/images/paymentmethod/${value.id}.png`} alt={`Payment Method ${value.bankname}`} title={`Payment Method ${value.bankname}`} className='img-fluid' />
                        <div className='crumb p-1'>{value.BankName}</div>
                        </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    )
}
