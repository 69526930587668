import React from 'react'
import $ from 'jquery'
import { Link, useParams } from 'react-router-dom'
import axios  from 'axios';

export default function PayNow(props) {
    const params = useParams();
    const BID = params.BID;
    let CurrentBook = [];
    let CompanyDetails = [];
    var bodyFormData = new FormData();
    bodyFormData.append("BID",BID);
    if(props.getCourse)
    {
        props.getCourse.map((value, key) => (
            value.id == BID
            ?(
                CurrentBook['cName'] = value.cName,
                CurrentBook['id'] = value.id,
                CurrentBook['cPrice'] = value.cPrice,
                CurrentBook['cDiscount'] = value.cDiscount,
                CurrentBook['cShortName'] = value.cShortName,
                CurrentBook['cDetail'] = value.cDetail,
                CurrentBook['cCatID'] = value.CatID,
                CurrentBook['status'] = value.status
            )
            : null
        ));
    }
    if(props.getCompanyDetails)
    {
        props.getCompanyDetails.map((value, key) => (
            CompanyDetails['CompanyName'] = value.companyName,
            CompanyDetails['CompanyAddress'] = value.companyAddress,
            CompanyDetails['CompanyEmail'] = value.companyEmail,
            CompanyDetails['CompanyAddressLink'] = value.companyAddressLink,
            CompanyDetails['CompanyPhone'] = value.companyPhone,
            CompanyDetails['CompanyFacebook'] = value.companyFacebook,
            CompanyDetails['CompanyTwitter'] = value.companyTwitter,
            CompanyDetails['CompanyInstagram'] = value.companyInstagram,
            CompanyDetails['CompanyLinkedin'] = value.companyLinkedin,
            CompanyDetails['CompanyCurrency'] = value.companyCurrency
        ));
    }

    const setFormValue = (e) => {
        bodyFormData.delete(e.target.name);
        bodyFormData.append(e.target.name,e.target.value);
    };

    const getImageView = (e) => {
        if (e.target.files && e.target.files[0]) 
        {
            $("#ScreenShotView").attr("src",(URL.createObjectURL(e.target.files[0])));
            bodyFormData.append(e.target.name,$('#txtScreenShot').get(0).files[0]);
        }
    } 

    function InputFocus(event)
    {
        $(event.target).attr("placeholder","");
        $(event.target).prev().css({"display":"block"});
    }

    function InputBlur(event)
    {
        $(event.target).attr("placeholder",$(event.target).prev().html());
        $(event.target).prev().css({"display":"none"});
        setFormValue(event);
    }

    const PaymentVerification = (e) => 
    {
        e.preventDefault();
        if($("#txtScreenShot").val() == "")
        {
            alert("Please Upload ScreenShot");
            $("#txtScreenShot").focus();
            return false;
        }
        if($("#txtName").val() == "")
        {
            alert("Please Enter Name");
            $("#txtName").focus();
            return false;
        }
        if($("#txtPhone").val() == "")
        {
            alert("Please Enter Phone");
            $("#txtPhone").focus();
            return false;
        }
        if($("#txtAmount").val() == "")
        {
            alert("Please Enter Sended Amount");
            $("#txtAmount").focus();
            return false;
        }
        if($("#txtSendedFromAcc").val() == "")
        {
            alert("Please Enter Accont No Payment Sended From");
            $("#txtSendedFromAcc").focus();
            return false;
        }
        if($("#txtTransactionID").val() == "")
        {
            alert("Please Enter Transaction ID");
            $("#txtTransactionID").focus();
            return false;
        }
        if($("#txtPaymentSendOn").val() == "")
        {
            alert("Please Select Payment Method");
            $("#txtPaymentSendOn").focus();
            return false;
        }
        
        // $(".ProcessingScreen").css({"display":"block"});
        $(".ProcessingScreen").show();
        axios({
            method:"post",
            url:`${process.env.REACT_APP_PHPAPI}?SendVerificationForm`,
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
            processData: false,
            contentType: false,
        })
        .then(function (response) 
        {
            if(response.data[0].addStatus == "1")
            {
                alert("Thank you for payment we will confirm you after payment verification");
            }
            else{
                alert("This payment request have already submited please wait for verification.");
            }
            $("#MyVerificationForm")[0].reset();
            $("#ScreenShotView").attr("src","./assets/images/noscreenshot.jpg");
            $(".ProcessingScreen").hide();
        })
        .catch(function (response) 
        {
            alert("There is an error please try again later");
            $("#MyVerificationForm")[0].reset();
            $("#ScreenShotView").attr("src","./assets/images/noscreenshot.jpg");
            $(".ProcessingScreen").hide();
            console.log(response);
        });
    }

  return (
    <>
       <div className='crumb'>
            <Link to={`/Book/${CurrentBook['id']}/${CurrentBook['cName']}`}>{CurrentBook['cName']}</Link>&nbsp;&nbsp; / &nbsp;&nbsp;<strong>Pay Now</strong>
        </div>
        <div className='container'>
            <div className='row mt-3'>
                <div className='col-lg-12 mt-5'>
                  <h3 className='text-center'>Payment Method</h3>
                  <Link to="/PaymentMethod" className='d-block text-center' style={{"textDecoration":"none","color":"var(--ParimaryColor)"}}>View All</Link>
                  <ol>
                    <li>First open youu application and make a payment on our given account.</li>
                    <li>Fill this form with correct information for verification of your payment</li>
                    <li>Admin will verified your payment and give access to download book</li>
                  </ol>
                  <h3 className='mt-5 text-center my-5'>Payment Verification Form</h3>
                  <form id='MyVerificationForm' onSubmit={PaymentVerification}>
                    <div className='row'>
                        <div className='col-lg-12 text-end'>
                            <label form='txtScreenShot'>
                                <img id='ScreenShotView' style={{"width":"200px","height":"180px","border":"2px rgb(232,232,232) dashed"}} src='./assets/images/noscreenshot.jpg' className='img-fluid p-1' alt='Select ScreenShot' title='Select ScreenShot' />
                                <input onChange={getImageView} type="file" hidden className='form-control round-0 mt-2' id="txtScreenShot" accept="image/*" name='ScreenShot' />
                            </label>
                        </div>
                        <div className='animtedInput col-lg-6'>
                            <label>Enter Name</label>
                            <input type="text" className='form-control round-0 mt-2' id='txtName' name='name' onBlur={InputBlur} onFocus={InputFocus} placeholder='Enter Name' />
                        </div>
                        <div className='animtedInput col-lg-6'>
                            <label>Enter Phone</label>
                            <input type="number" className='form-control round-0 mt-2' id='txtPhone' name='phone' onBlur={InputBlur} onFocus={InputFocus} placeholder='Enter Phone' />
                        </div>
                        <div className='animtedInput col-lg-6'>
                            <label>Sended Amount</label>
                            <input type="number" className='form-control round-0 mt-2' id='txtAmount' name='sendedamount' onBlur={InputBlur} onFocus={InputFocus} placeholder='Sended Amount' />
                        </div>
                        <div className='animtedInput col-lg-6'>
                            <label>Sender Account No</label>
                            <input type="number" className='form-control round-0 mt-2' id='txtSendedFromAcc' name='senderAcc' onBlur={InputBlur} onFocus={InputFocus} placeholder='Sender Account No' />
                        </div>
                        <div className='animtedInput col-lg-6'>
                            <label>Transaction ID</label>
                            <input type="number" className='form-control round-0 mt-2' id='txtTransactionID' name='transactionID' onBlur={InputBlur} onFocus={InputFocus} placeholder='Transaction ID' />
                        </div>
                        <div className='animtedInput col-lg-6'>
                            <label>Select Method</label>
                            <select onChange={setFormValue}  className='form-control' id='txtPaymentSendOn' name='txtPaymentSendOn'>
                                <option value="0">Payment Method</option>
                                { props.getPaymentMethods && props.getPaymentMethods.map((value, key) => 
                                    (
                                        <option key={key} value={value.id}>{value.BankName}</option>
                                    )
                                )}
                            </select>
                        </div>
                        <div className='text-center col-lg-12'>
                            <button type="submit" className="btn btn-outline-primary round-0 myBtn CutStyle mt-4">
                                Submit Verification Form
                                <span className="borderCut TopLeftCut"></span>
                                <span className="borderCut BottomRightCut"></span>
                                <span className="borderCut BottomLeftCut"></span>
                                <span className="borderCut TopRightCut"></span>
                            </button>
                        </div>
                    </div>
                  </form>
                </div>
            </div>
        </div>
    </>
  )
}
