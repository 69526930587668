import React from 'react'
import { Link } from 'react-router-dom'

export default function Book(props) 
{
    let CompanyDetails = [];
    if(props.getCompanyDetails)
    {
        props.getCompanyDetails.map((value, key) => (
            CompanyDetails['CompanyName'] = value.companyName,
            CompanyDetails['CompanyAddress'] = value.companyAddress,
            CompanyDetails['CompanyEmail'] = value.companyEmail,
            CompanyDetails['CompanyAddressLink'] = value.companyAddressLink,
            CompanyDetails['CompanyPhone'] = value.companyPhone,
            CompanyDetails['CompanyFacebook'] = value.companyFacebook,
            CompanyDetails['CompanyTwitter'] = value.companyTwitter,
            CompanyDetails['CompanyInstagram'] = value.companyInstagram,
            CompanyDetails['CompanyLinkedin'] = value.companyLinkedin,
            CompanyDetails['CompanyCurrency'] = value.companyCurrency
        ));
    }

    return (
        <>
            <div className='crumb'>
                <Link to="/Home">Home</Link>&nbsp;&nbsp; / &nbsp;&nbsp;<strong>All Payment Methods</strong>
            </div>
            <div className='container mt-5'>
                <div className='row'>
                    <div className='col-lg-12 m-3 text-center mb-5'>
                        <h2 className='MainHeading'>All Payment Methods</h2>
                    </div>
                </div>
                { props.getPaymentMethods && props.getPaymentMethods.map((value, key) => 
                    (
                    value.status == 1
                    ?(
                        <div className='row'>
                            <div className='col-lg-2 col-sm-12'>
                                <img src={`./assets/images/paymentmethod/${value.id}.png`} className="img-fluid" alt={`Payment Method ${value.BankName}`} title={`Payment Method ${value.BankName}`} />
                            </div>
                            <div className='col-lg-10 col-sm-12'>
                                <ul className='contactList'>
                                    <li><span className='mylabel' style={{"width":"120px","display":"inline-block"}}>Bank Name:</span>  {value.BankName}</li>
                                    <li><span className='mylabel' style={{"width":"120px","display":"inline-block"}}>Acc Title:</span>  {value.AccTitle}</li>
                                    {
                                        value.BranchCode != 0
                                        ?(
                                            <li><span className='mylabel' style={{"width":"120px","display":"inline-block"}}>Branch Code:</span>  {value.BranchCode}</li>
                                        )
                                        :null
                                    }
                                    <li><span className='mylabel' style={{"width":"120px","display":"inline-block"}}>Account No:</span>  {value.AccNo}</li>
                                    {
                                        value.IBan != 0
                                        ?(
                                        <li><span className='mylabel' style={{"width":"120px","display":"inline-block"}}>IBAN:</span>  {value.IBan}</li>
                                        )
                                        :null
                                    }
                                </ul>
                            </div>
                        </div>
                    )
                    :null
                ))}
            </div>
        </>
    )
}
