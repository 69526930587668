import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import $ from 'jquery'

export default function PaymentMethod(props) 
{
    const navigate = useNavigate();
    let CompanyDetails = [];
    if(props.getCompanyDetails)
    {
        props.getCompanyDetails.map((value, key) => (
            CompanyDetails['CompanyName'] = value.companyName,
            CompanyDetails['CompanyAddress'] = value.companyAddress,
            CompanyDetails['CompanyEmail'] = value.companyEmail,
            CompanyDetails['CompanyAddressLink'] = value.companyAddressLink,
            CompanyDetails['CompanyPhone'] = value.companyPhone,
            CompanyDetails['CompanyFacebook'] = value.companyFacebook,
            CompanyDetails['CompanyTwitter'] = value.companyTwitter,
            CompanyDetails['CompanyInstagram'] = value.companyInstagram,
            CompanyDetails['CompanyLinkedin'] = value.companyLinkedin,
            CompanyDetails['CompanyCurrency'] = value.companyCurrency
        ));
    }

    setInterval(function()
    {
        let Scr = $(".Slider").scrollLeft() + 204;
        if(Scr <= ($('.Slider').prop("scrollWidth") - $('.Slider').width()))
        {
            $(".Slider").scrollLeft(Scr);
        }
        else if((Scr - 204) < ($('.Slider').prop("scrollWidth") - $('.Slider').width())){
          $(".Slider").scrollLeft(($('.Slider').prop("scrollWidth") - $('.Slider').width()));
        }
        else
        {
            $(".Slider").scrollLeft(0);
        }
    }, 5000);

    return (
        <>
            <div className='crumb'>
                <Link to="/Home">Home</Link>&nbsp;&nbsp; / &nbsp;&nbsp;<strong>All Books</strong>
            </div>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-12 m-3 text-center'>
                        <h2 className='MainHeading'>All Books</h2>
                    </div>
                    { props.getCourse && props.getCourse.map((value, key) => 
                        (
                        value.status == 1
                        ?(
                        <div key={key} className='col-lg-4'>
                        <div className='course'>
                            <div className='imgView' onClick={() => navigate(`/Book/${value.id}/${value.cName}`)}>
                            <img src={`./assets/images/${value.id}.webp` } alt={` ${value.cName} - ${CompanyDetails['CompanyName']} `} title={` ${value.cName} - ${CompanyDetails['CompanyName']} `} className='img-fluid' />
                            </div>
                            <div className='m-2'>
                            <h4>{value.cName}</h4>
                            <p align="justify">{value.cDetail.slice(0, 150)}</p>
                            <span className='mylabel bookOff'>Off {value.cDiscount}%</span>
                            <span className='mylabel bookPrice'>{ (value.cPrice - ((value.cDiscount / value.cPrice) * 100)).toFixed(2) }{CompanyDetails['CompanyCurrency']}</span>
                            </div>
                            <div className='ms-auto' style={{"width":"fit-content"}}>
                            <Link to={`/Book/${value.id}/${value.cName}`} className="btn btn-outline-primary round-0 myBtn CutStyle">
                                Download
                                <span className="borderCut TopLeftCut"></span>
                                <span className="borderCut BottomRightCut"></span>
                                <span className="borderCut BottomLeftCut"></span>
                                <span className="borderCut TopRightCut"></span>
                            </Link>
                            <Link to={`/Book/${value.id}/${value.cName}`} className="btn btn-outline-primary round-0 myBtn CutStyle">
                                More
                                <span className="borderCut TopLeftCut"></span>
                                <span className="borderCut BottomRightCut"></span>
                                <span className="borderCut BottomLeftCut"></span>
                                <span className="borderCut TopRightCut"></span>
                            </Link>
                            </div>
                        </div>
                        </div>
                        )
                        :null
                    ))}
                </div>
                <div className='col-lg-12 m-3 text-center mt-5'>
                    <h2 className='MainHeading'>Accepted Payment Method</h2>
                    <div className='Slider hScroll mt-5'>
                    { props.getPaymentMethods && props.getPaymentMethods.map((value, key) => 
                        (
                        <div key={key} className='Slide'>
                        <img src={`./assets/images/paymentmethod/${value.id}.png`} alt={`Payment Method ${value.bankname}`} title={`Payment Method ${value.bankname}`} className='img-fluid' />
                        <div className='crumb p-1'>{value.BankName}</div>
                        </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    )
}
