import React from 'react'
import { Link } from 'react-router-dom'

export default function Error() {
  return (
    <>
      <div className='crumb'>
            <Link to="/Home">Home</Link>&nbsp;&nbsp; / &nbsp;&nbsp;<strong>Error 404</strong>
        </div>
        <div className='container'>
            <div className='row mt-3'>
                <div className='col-lg-12 Error4o4 text-center'>
                  <span className='Error4o4' style={{"fontSize":"100px"}}>4o4</span><br/>Page Not Found
                </div>
            </div>
        </div>
    </>
  )
}
